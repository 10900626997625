var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4",attrs:{"id":"video-details"}},[_c('v-container',[_c('v-btn',{staticClass:"pl-0",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Channel videos")],1),_c('h2',{staticClass:"mt-5"},[_vm._v("Video details")]),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-3",attrs:{"loading":_vm.inputLoading,"error-messages":errors,"label":"Title (required)","filled":"","dense":"","counter":"100","max-length":"100"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Description","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"loading":_vm.inputLoading,"filled":"","auto-grow":"","error-messages":errors,"label":"Description","placeholder":"Tell viewers about your video","rows":"5","counter":"5000","max-length":"5000","row-height":"20"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Visibilty","rules":"required|oneOf:private,public"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"loading":_vm.inputLoading,"items":_vm.visibility,"error-messages":errors,"filled":"","label":"Visibilty","value":_vm.formData.visibility},model:{value:(_vm.formData.visibility),callback:function ($$v) {_vm.$set(_vm.formData, "visibility", $$v)},expression:"formData.visibility"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Cateogry","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"loading":_vm.categoryLoading,"items":_vm.categoriesTitles,"error-messages":errors,"filled":"","label":"Categories"},model:{value:(_vm.formData.category),callback:function ($$v) {_vm.$set(_vm.formData, "category", $$v)},expression:"formData.category"}})]}}],null,true)}),_c('div',{staticClass:"mt-6 d-flex justify-space-between"},[_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.submitLoading,"type":"submit","depressed":""}},[_vm._v("Submit")])],1)],1)]}}])})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.toggleShow}},[_vm._v("Upload Thumbnails")]),_c('my-upload',{attrs:{"field":"thumbnail","method":"PUT","width":1280,"height":720,"url":_vm.url,"headers":_vm.headers,"img-format":"jpg","langType":"en"},on:{"crop-success":_vm.cropSuccess},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('v-responsive',{staticStyle:{"max-width":"100%"}},[(!_vm.imgDataUrl)?_c('div',{staticClass:"px-12",attrs:{"id":"image-placeholder"}},[_c('v-icon',[_vm._v("mdi-image-plus")])],1):_c('v-img',{attrs:{"crossorigin":"anonymous","height":"350","src":_vm.imgDataUrl}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }